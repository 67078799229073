import { Row } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { SvgIcon } from "../../common/SvgIcon";
import { HeaderSection, Label, LogoContainer } from "./styles";

const Header = () => {
  return (
    <HeaderSection>
      <Container>
        <Row justify="center">
          <Label>Sua opinião sobre a</Label>
        </Row>
        <Row justify="center">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon
              src="virtex-white-logo.png"
              width="220px"
              height="auto"
              animation={false}
            />
          </LogoContainer>
        </Row>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
