import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

    :root{
        --rainbow-0: #b72025;
        --rainbow-1: #d62027;
        --rainbow-2: #f05223;
        --rainbow-3: #f36f21;
        --rainbow-4: #faa823;
        --rainbow-5: #ffca27;
        --rainbow-6: #ecdb12;
        --rainbow-7: #e8e73d;
        --rainbow-8: #c5d92d;
        --rainbow-9: #80F74C;
        --rainbow-10: #00FF00;
    }


    body,
    html,
    a {
        font-family: 'Poppins', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        /* background-image: url('/img/svg/FUNDO.png'); */
        /* background-repeat: no-repeat;
        background-size: cover;
        background-color:#660505;
        overflow-x: hidden; */
    }

    a:hover {
        color: #FFFFFF;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: #fff;
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 0.5rem 1rem;

        :focus-within {            
            box-shadow: #F60000 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Poppins', sans-serif;
        /* color: #fff; */
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #fff;
        font-size: 21px;        
        line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #000;

        :hover {
            color: #FFFFFF;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }
`;
