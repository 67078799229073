const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "NotPermitted",
  },
  {
    path: ["/:hash", "/home/:hash"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/:hash/0", "/home/:hash/0"],
    exact: true,
    component: "AlreadyDone",
  },
  {
    path: ["/:hash/1/:name", "/home/:hash/1/:name"],
    exact: true,
    component: "Detrator",
  },
  {
    path: ["/:hash/2/:name", "/home/:hash/2/:name"],
    exact: true,
    component: "Neutro",
  },
  {
    path: ["/:hash/3/:name", "/home/:hash/3/:name"],
    exact: true,
    component: "Promotor",
  }
];

export default routes;
