import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderSection = styled("header")`
  padding: 1rem 0.5rem;
  background-color: #d50000;

  .ant-row-space-between {
    align-items: center;
    text-align: center;
  }
`;

export const Label = styled("span")`
  font-weight: bold;
  color: #fff;
  font-size: 1.3rem;
`;

export const LogoContainer = styled(Link)`
  display: flex;
`;